/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders Wrap Layout Component
 *
 * @param {object} props - Child Elements
 * @returns {ReactElements} Wrap Layout Component
 */
const WrapLayout = props => {
  return (
    <Box
      float="left"
      w="100%"
      margin={props.margin}
      p={{ base: props.pad, lg: !isNaN(props.pad) ? props.pad : '0' }}
    // ? overflow="hidden" need to verify
    >
      {props.children}
    </Box>
  );
};

export default WrapLayout;
